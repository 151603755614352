











import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OAccommodationAddHotel: () =>
      import(
        "@/components/organisms/event/modules/accommodationModule/o-accommodation-add-hotel.vue"
      ),
  },

  setup() {
    return {};
  },
});
